import React from 'react';
import { NavLink } from 'react-router-dom';
import companyImage from '../images/company.png';
import styles from './Layout.module.css'; // Import CSS module

interface LayoutProps {
    children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {

    const createNavLink = (to: string, label: string) => (
        <NavLink
            to={to}
            className={({ isActive }) =>
                isActive ? `${styles.link} ${styles.activeLink}` : styles.link
            }
        >
            {label}
        </NavLink>
    );

    return (
        <div className={styles.siteContainer}>


            <div className={styles.navbarContainer}>
                <nav className={styles.nav}>
                    {createNavLink("/", "Home")}
                    {createNavLink("/about", "About Us")}
                    {createNavLink("/services", "Services")}
                    {createNavLink("/contact", "Contact Us")}
                </nav>
            </div>

            <div className={styles.imageContainer}>
                <img className={styles.image} src={companyImage} alt="Our Company"/>
                {children}
            </div>

            <footer className={styles.footer}>
                CIL ENTERPRISES<br/>
                Copyright © 2024 - CIL Enterprises Holdings Inc.<br/>
                All Rights Reserved
            </footer>
        </div>
    );
};

export default Layout;
