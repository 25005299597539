import React from 'react';

const About: React.FC = () => (
    <div>
        <h1>About Us</h1>
        <p>This is a restored page.</p>
    </div>
);

export default About;
