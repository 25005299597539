import React from 'react';

const Services: React.FC = () => {
    return (
        <div>
            <h1>Services</h1>
            <p>This is a restored page.</p>
        </div>
    );
}

export default Services;
