import React from 'react';
import { CSSProperties } from 'react';

const Contact: React.FC = () => {
    const styles: Record<string, CSSProperties> = {
        container: {
            textAlign: 'center',
            padding: '50px',
        },
        header: {
            fontSize: '2rem',
            color: '#333',
            marginBottom: '20px',
        },
        description: {
            fontSize: '1.2rem',
            color: '#666',
            marginBottom: '30px',
            textAlign: 'justify', // Justify the text alignment
            textJustify: 'inter-word',
            maxWidth: '1200px', // Set a max-width for better readability
        },
        emailLink: {
            fontSize: '1.5rem',
            color: '#007bff',
            textDecoration: 'none',
            padding: '10px 20px',
            border: '2px solid #007bff',
            borderRadius: '5px',
            transition: 'all 0.3s ease',
        },
        paragraph: {

        }
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Contact Us</h1>
            <p style={styles.description}>
                We value your interest and are eager to hear from you.
                Whether it's a question, feedback, or a potential project, feel free to reach out.
                Our team is dedicated to providing timely and thoughtful responses to all inquiries.
                For direct communication, you may prefer using the email link provided below.
            </p>            <a href="mailto:mcil@cilenterprises.com"
               style={styles.emailLink}>mcil@cilenterprises.com</a>
        </div>
    );
};

export default Contact;
