import React from 'react';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column', // Stack items vertically
        alignItems: 'center', // Center items horizontally
        textAlign: 'center', // Center text within each item
        minHeight: '40vh', // Full height of the viewport
        width: "100%"
        // justifyContent: 'center', // Center the content vertically as well
    } as React.CSSProperties,
    header: {
        // marginBottom: '1rem', // Add some space below the header
    } as React.CSSProperties,
    paragraph: {
        textAlign: 'justify', // Justify the text alignment
        textJustify: 'inter-word',
        maxWidth: '1200px', // Set a max-width for better readability
    } as React.CSSProperties,
};

const Home: React.FC = () => {
    const companyName = "Cil Enterprises"; // Replace with your actual company name

    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Welcome</h1>
            <p style={styles.paragraph}>
                Welcome to {companyName}, where the legacy of gold commerce, refined through over five centuries, is
                seamlessly woven into the fabric of the digital age. Our roots run deep in the annals of history,
                tracing back to a time when gold was the currency of empires, the measure of wealth, and the heart of
                commerce. Today, as pioneers at the confluence of globalization and digitalization, we carry forward
                this rich heritage, integrating the timeless value of gold with the boundless possibilities of modern
                technology.
                <br/><br/>
                Our journey began over 500 years ago, with our forebears navigating the ancient trade routes, their
                caravans laden with gold, the beacon of prosperity. Through the ages, we've evolved, from the bustling
                bazaars of old to the dynamic global markets of today, always with gold at the core of our commerce.
                At {companyName}, we honor this tradition by bringing the trusted and treasured practice of gold trading
                into the 21st century, employing cutting-edge digital platforms that transcend borders and connect
                cultures.
                <br/><br/>
                Our expertise is unparalleled, forged through generations of dedication to the craft of gold commerce.
                We've witnessed the world change, empires rise and fall, and economies shift, yet our commitment to the
                gold trade has remained steadfast. Now, we harness the power of digital technology to revolutionize this
                ancient practice, offering secure, transparent, and efficient online platforms for buying, selling, and
                investing in gold, making it accessible to a global audience.
                <br/><br/>
                At {companyName}, we don't just trade gold; we are custodians of a legacy that dates back centuries. We
                blend the reliability, trust, and intrinsic value of gold with the innovation, speed, and connectivity
                of digital solutions. Our mission is to empower you, our valued partner, to partake in this golden
                legacy, leveraging our deep-rooted expertise and modern technology to achieve unparalleled success in
                the global digital marketplace.
                <br/><br/>
                Join us, and be part of a continuous journey that spans over half a millennium. Together, we'll navigate
                the future, anchored by the enduring value of gold and propelled by the limitless potential of
                digitalization. Welcome to {companyName}, where history meets the future, and your business's potential
                is as infinite as the legacy we share.
            </p>
        </div>
    );
};

export default Home;
